import {InjectionToken} from '@angular/core';
import {BszFactoryProviderGenerator} from '@basuiz/web-app-applet-api';

export const ɵPAGE_SIZE_FOR_OVERVIEW = new InjectionToken<number>('bsz.web-app-common.page-sizes.overview', {
  factory: () => 50,
});

/**
 * Factory provider for a convenient overwrite of the page size for applets displaying a full list of items.
 * E.g.: Portfolio List, Payment Overview, Booking List, etc.
 * Can be provided multiple times in the root provider scope and in nested provider scopes if different applets
 * should use different page sizes.
 * */
export const providePageSizeForOverview: BszFactoryProviderGenerator<number> = (factory, deps) => ({
  provide: ɵPAGE_SIZE_FOR_OVERVIEW,
  useFactory: factory,
  deps,
});
