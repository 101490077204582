import {shipmentIdLabelToken, shipmentObjectUpdatedLabelToken} from '../..';
import {ApiHttpErrorCodes} from '../../../api-http-error-codes';
import {
  DATE_TIME_FORMAT_SHORT,
  getNowDateTimeInUTC,
  parseDateToLocaleTimezone,
  textSeparator,
  toFixedAndRoundCurrency,
} from '../../../utils/common';
import {CollectiveCarrierToken} from '../../collective-carrier/data-access/collective-carrier.model';
import {IRate} from '../../rate/data-access/rate.model';

/**
 * @return based on the preferred rate, the amount to be paid now (this is at time of the execution of the transaction shipment-purchase)
 */
export function getShipmentPurchaseTotalToPay(rate: IRate): number | null {
  if (!rate) {
    return null;
  }

  const collectiveCarrierToken: CollectiveCarrierToken =
    rate?.individualServiceLevel?.collectiveServiceLevel?.collectiveCarrier?.token!;

  if (!collectiveCarrierToken) {
    throw new Error(ApiHttpErrorCodes.shipmentPaymentIntentError);
  }

  let totalToPayNow: number;
  if (collectiveCarrierToken === CollectiveCarrierToken.ec_delivereo) {
    totalToPayNow = rate.profitTotal ?? 0;
  } else {
    totalToPayNow = (rate.profitTotal ?? 0) - getShipmentCollectionTotalToPay(rate)!;
  }

  return toFixedAndRoundCurrency(totalToPayNow);
}

/**
 * @return based on the preferred rate, the amount to be paid later (this is after the execution of the transaction shipmentPurchasePaymentIntent)
 * Currently only the collectionFee is set to be paid later in time (it should be charged on the shipmentCollectionPaymentIntent)
 */
export function getShipmentCollectionTotalToPay(rate: IRate): number | null {
  if (!rate) {
    return null;
  }

  const collectiveCarrierToken: CollectiveCarrierToken =
    rate?.individualServiceLevel?.collectiveServiceLevel?.collectiveCarrier?.token!;

  if (!collectiveCarrierToken) {
    throw new Error(ApiHttpErrorCodes.shipmentPaymentIntentError);
  }

  let totalToPayLater: number;
  if (collectiveCarrierToken === CollectiveCarrierToken.ec_delivereo) {
    totalToPayLater = 0;
  } else {
    totalToPayLater = (rate.profitCollection ?? 0) + (rate.profitCollectionVat ?? 0);
  }

  return toFixedAndRoundCurrency(totalToPayLater);
}

/**
 * @return the shipment label watermark with the shipment object id and the current date and time in the default app locale and timezone.
 * Usually we use this to leave a watermark in the shipment label to identify the shipment (when it was generated in the carrier's API).
 */
export function getShipmentLabelWatermark(shipmentObjectId: number): string {
  if (!shipmentObjectId) {
    throw new Error(ApiHttpErrorCodes.shipmentLabelWatermarkError);
  }

  const shipmentLabelWatermark = `${
    process.env['APP_NAME']
  }${textSeparator}${shipmentIdLabelToken}:${shipmentObjectId}${textSeparator}${shipmentObjectUpdatedLabelToken}:${parseDateToLocaleTimezone(
    getNowDateTimeInUTC().toJSDate(),
    DATE_TIME_FORMAT_SHORT
  ).replace(/ /g, '')}`;

  return shipmentLabelWatermark;
}
