import {Relation} from 'typeorm';
import {Currency} from '../../../utils/models';
import {IBaseAdminEditor, IBaseUserOwnedEntity} from '../../core/data-access/base-models/base-user-owned.model';
import {Role} from '../../core/data-access/user/roles/role.model';
import {IUser} from '../../core/data-access/user/user.model';
import {IPaylogEcPayphone} from '../../paylog/data-access/paylog-ec-payphone/paylog-ec-payphone.model';
import {IPaymentMethod, PaymentMethodType} from '../../payment-method/data-access/payment-method.model';
import {IShipment} from '../../shipment/data-access/shipment.model';
import {IWalletTransaction} from '../../wallet-transaction/data-access/wallet-transaction.model';
import {WalletSource} from '../../wallet/data-access/wallet.model';

export const paymentIntentIdToken = 'PINTID';

export const PAYMENT_INTENT_TOPUP_MIN_AMOUNT = 2; // in USD, business decision
export const PAYMENT_INTENT_TOPUP_MAX_AMOUNT = 10000; // in USD, business decision
export const PAYMENT_INTENT_PAYOUT_MIN_AMOUNT = 20; // in USD, business decision

export enum PaymentIntentStatus {
  /**
   * @param requiresPaymentMethod is the default status when an PaymentIntent object is created, and a paymentMethod was not provided. This is useful when we want to capture a PaymentIntent on its origin without the user has defined its selected payment method.
   * This paymentIntent should NOT be used to be executed via a walletTransfer.
   */
  requiresPaymentMethod = 'requiresPaymentMethod',

  /**
   * @param userNotifiedPaymentWasSent is a payment which was requested and the user has already done (in the past) the payment via card or bank transfer.
   * This paymentIntent could be used to be executed via a walletTransfer.
   * This status is technically the same as 'processing', but we should use 'userNotifiedPaymentWasSent' when the paymentIntent was created by a 'Role.user' to register that a person-user performed an action in a third-party app in order to execute a payment (ex. when a user executes a bank transfer in its own online banking app and we don't have a connection with it).
   * When a paymentIntent is created by a (API/APP/robot) code-user it's recommended to use the status 'processing'.
   */
  userNotifiedPaymentWasSent = 'userNotifiedPaymentWasSent',

  /**
   * @param userNotifiedPaymentIsPending is a payment which was requested and the user has not done yet the payment, the user should do the payment in the near future so it's still pending.
   * This paymentIntent could be used to be executed via a walletTransfer.
   */
  userNotifiedPaymentIsPending = 'userNotifiedPaymentIsPending',

  /**
   * @param processing is a payment which the PaymentIntent moves to processing for asynchronous payment methods, such as bank debits. It can take several days to confirm whether the payment was successful, during this time the payment can't be guaranteed.
   * This paymentIntent could be used to be executed via a walletTransfer.
   */
  processing = 'processing',

  /**
   * @param captured is a payment which the PaymentIntent has successfully capture the funds that were requested to be collected, AND this funds has not yet been executed.
   * This paymentIntent could be used to be executed via a walletTransfer.
   */
  captured = 'captured',

  /**
   * @param canceled is a payment which was requested and the user wants to cancel the paymentIntent, it's not clear if the user has done the payment or not in the past/future.
   * This paymentIntent should NOT be used to be executed via a walletTransfer.
   */
  canceled = 'canceled',

  /**
   * @param failed is a payment which was requested and for any reason this payment failed. This status could be trigger when the paymentIntent was not executed in a certain amount of time and it was set to 'failed' automatically (ex., after creation, there's max 1 day to execute it, otherwise it's set to 'failed').
   * This paymentIntent should NOT be used to be executed via a walletTransfer.
   */
  failed = 'failed',

  /**
   * @param succeeded is a payment intent which was requested and completely executed via a walletTransaction and funds were credited to the user's Wallet, therefore this paymentIntent should not be executed twice.
   * This paymentIntent should NOT be used to be executed via a walletTransfer.
   */
  succeeded = 'succeeded',
}

/**
 * This PaymentIntentStatus are the ONLY ones allowed to execute a walletTransaction credit/debit
 *
 * After a paymentIntent was successfully executed, it should be changed to PaymentIntentStatus.succeeded.
 */
export const PaymentIntentValidStatusToExecute = [
  PaymentIntentStatus.processing,
  PaymentIntentStatus.userNotifiedPaymentWasSent,
  PaymentIntentStatus.userNotifiedPaymentIsPending,
  PaymentIntentStatus.captured,
];

/**
 * This PaymentIntentStatus are the ONLY ones allowed to be canceled
 */
export const PaymentIntentValidStatusToCancel = [
  PaymentIntentStatus.requiresPaymentMethod,
  PaymentIntentStatus.processing,
  PaymentIntentStatus.userNotifiedPaymentWasSent,
  PaymentIntentStatus.userNotifiedPaymentIsPending,
  PaymentIntentStatus.captured,
];

/**
 * This PaymentIntentStatus are the ONLY ones allowed to be captured
 *
 * ---
 * Lifecycle to capture a payment:
 * 1. Create a payment with 'PaymentIntentStatus.processing
 * 2. Run 'capture' paymentIntent transaction which changes to PaymentIntentStatus.captured
 * 3. Run 'execute' paymentIntent transaction which changes to PaymentIntentStatus.succeeded
 */
export const PaymentIntentValidStatusToCapture = [PaymentIntentStatus.processing];

export enum PaymentIntentCreditAction {
  /**
   * @param topup is a payment which MUST CREDIT an amout directly to the Wallet.balance.
   * When this PaymentIntent is applied to a paymentIntent.walletDestination='tokenized', this amount should come from a external source that must be tokenized (not withdrawn), for ex. bank account, credit card, cash, refundFunds, etc,
   * When this PaymentIntent is applied to a paymentIntent.walletDestination='available', this amount should come from a external source that must NOT be tokenized (could be withdrawn), for ex. collectionFunds, etc,
   * When this PaymentIntent is applied to a paymentIntent.walletDestination='giftcard', this amount does not come as real-money from any source, as this amount is being paid by the company as 'marketingFunds'.
   */
  topup = 'topup',
}

export enum PaymentIntentDebitAction {
  /**
   * @param purchase is a payment or purchase of an order or product, which MUST DEBIT an amout directly to the Wallet.balance.
   */
  purchase = 'purchase',

  /**
   * @param payout is a payment which MUST DEBIT an amout directly to the Wallet.balance.
   * A payout MUST come from an existing list of order's collection objects, for ex. user request a payout of the amout collected from a user request's to collect money from a third-party.
   *
   * ---
   * Lifecycle of a Collection and Payout:
   *
   * (USER)   |    (APP)    |   (COLLECTOR)     |   Status
   *
   *   A --- (1) --> B                          |   Collection requested
   *                 C --- (2) --> D            |   Collection requested
   *                      (...)                 |   Waiting some time... (collecting is in progress)
   *                 F <-- (3) --- E            |   Collection collected success
   *   H <-- (4) --- G                          |   Collection collected success: change to paymentIntent.status=captured (DO NOT execute it yet)
   *        (...)                               |   Waiting some time... (usually the app has a fixed schedule to execute the collection's paymentIntents periodically)
   *   J <-- (5) --- I                          |   Credit User's wallet: execute the paymentIntent (this changes to paymentIntent.status=succeeded)
   *        (...)                               |   At any time in the future...
   *   K --- (6) --> L                          |   Payout requested
   *   N <-- (7) --- M                          |   Payout executed success
   *
   * ---
   * 1. (A->B) User request collection:
   *              - User requests the App to collect an amount of money for the value of its products, to be billed to a third-person (usually his own sub-client).
   *              - Here the app creates a paymentIntent (payint_1) linked to the 'paymentMethod=collectionFunds' and 'creditAction=topup', and 'status=processing'.
   *
   * 2. (C->D) App request collection:
   *              - App requests the Collector to collect the amount requested by the user.
   *
   * 3. (F<-E) Collector notifies of collection success:
   *              - The Collector notifies the App that the value to be collected, was successfully collected.
   *
   * 4. (H<-G) App notifies User of the collection success:
   *              - The App notifies the User that the value to be collected, was successfully collected.
   *              - Here we should ONLY change the paymentIntent.status=captured and DO NOT execute it yet.
   *
   *
   * 5. (J<-I) App credits user wallet:
   *              - Here we should execute the payment intent created in step 1 (payint_1), which will CREDIT (topup) the user's 'wallet.source=available' with the amount collected (minus a collection appFee)
   *
   * 6. (K->L) User request payout:
   *              - User requests the App to payout the some (all) collected money from its 'wallet.source=available'
   *              - Here the app creates a paymentIntent (payint_2) linked to the 'paymentMethod=walletAvailable' and 'debitAction=payout', and 'status=processing'.
   *
   * 7. (N<-M) App executes payout:
   *              - First the app executes a transfer-out or debit from the bank account of collections.
   *              - The app executes the paymentIntent (payint_2) and transfer-out or debit funds from the 'wallet.source=available'.
   */
  payout = 'payout',

  /**
   * @param generalDebit is a payment which MUST DEBIT an amout directly to the Wallet.balance.
   * Use this for non-specified DEBIT transactions (ex. a user deleted account and requests a complete withdrawal of funds; or an human admin-user error over-credited a wallet)
   */
  generalDebit = 'generalDebit',
}

export type PaymentMethodAllowedPaymentIntentActions = {
  [key in PaymentMethodType]:
    | {[key in PaymentIntentDebitAction]: boolean} // for debit actions
    | {[key in PaymentIntentCreditAction]: false | {[key in WalletSource]: boolean}}; // for credit actions, it's required to specify the walletDestination
};

/**
 * Given a X PaymentMethodType (the source of the funds), we define which actions are allowed to execute
 * from that specific source.
 * For example:
 * - from a 'PaymentMethodType.bankTransfer', we are allowed to 'PaymentIntentAction.topup' the captured funds in the bank trasaction and use it to topup the "wallet.source=tokenized"
 * - from a 'PaymentMethodType.walletTokenized', we are allowed to 'PaymentIntentAction.purchase' some product using the funds within the "wallet.source=tokenized".
 */
export const PaymentMethodAllowedPaymentIntentActionsAdminUser: PaymentMethodAllowedPaymentIntentActions = {
  [PaymentMethodType.walletTokenized]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: true,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: true,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: false,
    // Credit Actions - END
  },
  [PaymentMethodType.walletAvailable]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: true,
    [PaymentIntentDebitAction.payout]: true,
    [PaymentIntentDebitAction.purchase]: false, // here, we must ask the user to first transfer funds from 'wallet.source=available' to 'wallet.source=tokenized' in order to make a purchase within the app.
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true, // in order to use the funds in 'wallet.source=available', we first must transfer an amount from 'wallet.source=available' to 'wallet.source=tokenized', and ONLY after that use this amount within the 'wallet.source=tokenized'.
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.walletGiftcard]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: true,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: true,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: false,
    // Credit Actions - END
  },
  [PaymentMethodType.refundFunds]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.marketingFunds]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: true,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.collectionFunds]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: false,
      [WalletSource.available]: true, // the 'PaymentMethodType.collectionFunds' can only be credited into 'wallet.source=available', because this is the only wallet that allows later payouts/withdrawal
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.cash]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.bankTransfer]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.ecPayphone]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
};

export const PaymentMethodAllowedPaymentIntentActionsAppUser: PaymentMethodAllowedPaymentIntentActions = {
  [PaymentMethodType.walletTokenized]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: true,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: false,
    // Credit Actions - END
  },
  [PaymentMethodType.walletAvailable]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: true,
    [PaymentIntentDebitAction.purchase]: false, // here we must ask the user to first transfer funds from 'wallet.source=available' to 'wallet.source=tokenized' in order to make a purchase within the app.
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true, // in order to use the funds in 'wallet.source=available', we first must transfer an amount from 'wallet.source=available' to 'wallet.source=tokenized', and ONLY after that use this amount within the 'wallet.source=tokenized'.
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.walletGiftcard]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: true,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: false,
    // Credit Actions - END
  },
  [PaymentMethodType.refundFunds]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: false, // TODO p2: change to 'true' when a cancelation of purchase can be completed without human intervention. Currently there's the need of an admin-user to manually cleanup all purchase's traces/products/shipments/suppliers (in external apps) in order to refund an app-user.
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.marketingFunds]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: false,
    // Credit Actions - END
  },
  [PaymentMethodType.collectionFunds]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: false,
      [WalletSource.available]: true, // the 'PaymentMethodType.collectionFunds' can only be credited into 'wallet.source=available', because this is the only wallet that allows later payouts/withdrawal
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.cash]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: false,
    // Credit Actions - END
  },
  [PaymentMethodType.bankTransfer]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
  [PaymentMethodType.ecPayphone]: {
    // Debit Actions - START
    [PaymentIntentDebitAction.generalDebit]: false,
    [PaymentIntentDebitAction.payout]: false,
    [PaymentIntentDebitAction.purchase]: false,
    // Debit Actions - END
    //
    // Credit Actions - START
    [PaymentIntentCreditAction.topup]: {
      [WalletSource.tokenized]: true,
      [WalletSource.available]: false,
      [WalletSource.giftcard]: false,
    },
    // Credit Actions - END
  },
};

export enum PaymentIntentAdminVouch {
  eb = 'eb', // private user eb
  sc = 'sc', // private user sc
  as = 'as', // private user as
  af1 = 'af1', // admin finance 1
  af2 = 'af2', // admin finance 2
  af3 = 'af3', // admin finance ...
  af4 = 'af4',
  af5 = 'af5',
  af6 = 'af6',
  af7 = 'af7',
  af8 = 'af8',
  af9 = 'af9',
  af10 = 'af10',
  af11 = 'af11',
  af12 = 'af12',
  af13 = 'af13',
  af14 = 'af14',
  af15 = 'af15',
  af16 = 'af16',
  af17 = 'af17',
  af18 = 'af18',
  af19 = 'af19',
  af20 = 'af20',
}

export type PaymentIntentExecuteRequest = Required<
  Pick<IPaymentIntent, 'objectId' | 'objectOwnerId' | 'extTransactionId' | 'extTransactionAdminVouch' | 'appFee'>
> &
  Pick<IPaymentIntent, 'isDeferred' | 'privateNote' | 'publicNote'> &
  Pick<IBaseAdminEditor, 'objectAdminEditorId' | 'objectAdminEditorUpdated'>;

export type PaymentIntentExecuteTransactionInput = {
  paymentIntent: PaymentIntentExecuteRequest;
  executeFromRole: Role.adminFinance | Role.user;
};

export type PaymentIntentCancelRequest = Required<Pick<IPaymentIntent, 'objectId' | 'objectOwnerId'>> &
  Pick<IPaymentIntent, 'privateNote' | 'publicNote' | 'extTransactionId' | 'extTransactionAdminVouch'> &
  Pick<IBaseAdminEditor, 'objectAdminEditorId' | 'objectAdminEditorUpdated'>;

export type PaymentIntentCancelTransactionInput = {
  paymentIntent: PaymentIntentCancelRequest;
  executeFromRole: Role.adminFinance | Role.user;
};

export type PaymentIntentCaptureRequest = Required<
  Pick<IPaymentIntent, 'objectId' | 'objectOwnerId' | 'extTransactionAdminVouch'>
> &
  Pick<IBaseAdminEditor, 'objectAdminEditorId' | 'objectAdminEditorUpdated'>;

export type PaymentIntentCaptureTransactionInput = {
  paymentIntent: PaymentIntentCaptureRequest;
  executeFromRole: Role.adminFinance | Role.user;
};

export type ShipmentPurchaseTransactionInput = {
  shipment: Required<Pick<IShipment, 'objectId' | 'objectOwnerId'>>;
  paymentMethod: Pick<IPaymentMethod, 'type'>;
};

export type ShipmentCollectionCaptureTransactionInput = {
  shipment: Required<Pick<IShipment, 'objectId' | 'objectOwnerId'>>;
  paymentIntent: Required<Pick<IPaymentIntent, 'extTransactionAdminVouch'>>;
};

export type ShipmentCollectionExecuteTransactionInput = {
  shipment: Required<Pick<IShipment, 'objectId' | 'objectOwnerId'>>;
  paymentIntent: PaymentIntentExecuteRequest;
  executeFromRole: Role.adminFinance;
};

/**
 * Reference inspiration:
 * - How PaymentIntents work: https://stripe.com/docs/payments/paymentintents/lifecycle#intent-statuses
 * - Use 'PaymentIntent' instead of soon to be deprecated 'Charges': https://docs.stripe.com/payments/payment-intents/migration/charges
 */
export interface IPaymentIntent extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  currency: Currency;

  // TODO p2 should never be a negative number, add a DB validator. Internally a debit transaction must substract this 'amount' value, and a credit transaction must add this 'amount' value.
  amount: number;

  /**
   * @param appFee is a payment which MUST DEBIT an amout directly to the Wallet.balance.
   * Use this to debit general fees from the application (ex. app subscription fees, credit card processing fees.)
   * This value is optional and only occurs when an external-app charges a fee for its use.
   */
  // TODO p2 should never be a negative number, add a DB validator. Internally a debit transaction must substract this 'appFee' value.
  appFee?: number;

  status: PaymentIntentStatus;

  /**
   * @member `extTransactionId` refers to the "unique" ID seen in the transaction of the external payment processing application.
   * For example, if the user does a bank transaction topup then the bank transaction ID of the trasfer;
   * if the user makes a credit card topup then the ID of transaction of the card processor app after a successful charge.
   * This field is NOT optional so we force an admin user to insert this value, but when we don't have an ID of the payment platform,
   * we must fill this value with 'NA', so when the app recognizes this special token it must fill automatically the field
   * `extTransactionAdminVouch` with the name/id of the logged admin-user.
   */
  extTransactionId: string;

  extTransactionAdminVouch?: PaymentIntentAdminVouch;

  /**
   * @member isDeferred when true refers to a payment that is due later in time, i.e., this amount is owned by this user currently. Usually on credit actions when the user is
   * expected to pay the amount later but we need to topup the wallet now so the user can use this amount now in his wallet. There must be a manual or automatic check in a third-party
   * system (example in a bank transfer) later in time, where this amount will be paid and this paymentIntent can be set the 'isDeferredPaid' to true.
   * @default false
   */
  isDeferred?: boolean;

  /**
   * @member isDeferredPaid when true refers to a payment which was marked as 'isDeferred' when created, and a manual or automatic check in a third-party
   * system (example in a bank transfer) has been performed and this amount has been certified as efectively paid by the user, i.e., this amount is NOT owned anymore by this user.
   * @default false
   */
  isDeferredPaid?: boolean;

  /**
   * Free text to insert a internal and private description of this paymentIntent, intended for admin-users
   */
  privateNote?: string;

  /**
   * Free text to insert a public description of this paymentIntent, intended for real app users
   */
  publicNote?: string;

  paymentMethodObjectId: number;
  /**
   * ManyToOne relation to paymentMethod
   */
  paymentMethod?: Relation<IPaymentMethod>;

  creditAction?: PaymentIntentCreditAction;

  debitAction?: PaymentIntentDebitAction;

  /**
   * When a PaymentIntentAction is meant to CREDIT an amount,
   * we MUST specify to which wallet this funds must be credited to.
   * ---
   * When a PaymentIntentAction is meant to DEBIT an amount, this field should be empty,
   * as there's no action to transfer funds into a wallet.
   */
  walletDestination?: WalletSource;

  creditWalletTransactionObjectId?: number;
  /**
   * OneToOne relation to walletTransaction
   */
  creditWalletTransaction?: Relation<IWalletTransaction>;

  debitWalletTransactionObjectId?: number;
  /**
   * OneToOne relation to walletTransaction
   */
  debitWalletTransaction?: Relation<IWalletTransaction>;

  appFeeWalletTransactionObjectId?: number;
  /**
   * OneToOne relation to walletTransaction
   */
  appFeeWalletTransaction?: Relation<IWalletTransaction>;

  ////////////////////////////////////////////////////////////
  // Object that generate paymentIntents to credit/debit funds

  /**
   * OneToOne relation for a purchase of shipment.preferredRate
   */
  shipmentPurchase?: Relation<IShipment>;

  /**
   * OneToOne relation for a refund of shipment.preferredRate
   */
  shipmentRefund?: Relation<IShipment>;

  /**
   * OneToOne relation for a collectionRquest and topup of shipment.goodsCollection
   */
  shipmentCollection?: Relation<IShipment>;

  //
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Paylog relations

  paylogEcPayphoneObjectId?: number;
  /**
   * OneToOne relation to paylogEcPayphone
   */
  paylogEcPayphone?: Relation<IPaylogEcPayphone>;

  // paylog<NEW_PAYMENT_PROCESSOR>ObjectId?: number;
  // /**
  //  * OneToOne relation to paylog<NEW_PAYMENT_PROCESSOR>
  //  */
  // paylog<NEW_PAYMENT_PROCESSOR>?: Relation<IPaylog<NEW_PAYMENT_PROCESSOR>>;

  //
  ////////////////////////////////////////////////////////////
}
