import {ShipmentStatus} from '../../../shipment/data-access/shipment.model';

export enum EcDelivereoLabelStatus {
  'ACCEPTED' = 'ACCEPTED', // seen in webhook
  'ARRIVED_FIRST_POSITION' = 'ARRIVED_FIRST_POSITION',
  'ARRIVED_LAST_POSITION' = 'ARRIVED_LAST_POSITION',
  'ARRIVED_OTHER_POSITION' = 'ARRIVED_OTHER_POSITION',
  'CANCELED' = 'CANCELED',
  'CREATED' = 'CREATED',
  'FINISHED' = 'FINISHED',
  'GOING_FIRST_POSITION' = 'GOING_FIRST_POSITION',
  'GOING_LAST_POSITION' = 'GOING_LAST_POSITION',
  'GOING_OTHER_POSITION' = 'GOING_OTHER_POSITION',
  'GROUPING' = 'GROUPING',
  'MANUAL_ASSIGN' = 'MANUAL_ASSIGN',
  'NO_DRIVER_FOUND' = 'NO_DRIVER_FOUND',
  'RATING' = 'RATING',
  'SCHEDULED' = 'SCHEDULED',
  'TRANSFERRING_TO' = 'TRANSFERRING_TO',
}

/**
 * Usual Carrier tracking flow (without COD):
 * 1. CREATED / MANUAL_ASSIGN   (looking for a driver)
 * 2. GOING_FIRST_POSITION      (driver found and going to sender address)
 * 3. ARRIVED_FIRST_POSITION    (driver arrived at sender address)
 * 4. GOING_OTHER_POSITION      (driver going to recipient address)
 * 5. ARRIVED_LAST_POSITION     (driver arrived at recipient address)
 * 6. FINISHED                  (driver delivered package)
 *
 * When driver is not found:
 * 1. CREATED / MANUAL_ASSIGN   (looking for a driver)
 * 2. NO_DRIVER_FOUND           (driver not found and shipment should be annulled)
 */
export const EcDelivereoLabelStatusMap: {
  [key in EcDelivereoLabelStatus]: ShipmentStatus;
} = {
  // pickupRequested
  [EcDelivereoLabelStatus.SCHEDULED]: ShipmentStatus.pickupRequested, // NOTE: currently 'SCHEDULED' is not implemented
  [EcDelivereoLabelStatus.CREATED]: ShipmentStatus.pickupRequested,
  [EcDelivereoLabelStatus.MANUAL_ASSIGN]: ShipmentStatus.pickupRequested,
  [EcDelivereoLabelStatus.TRANSFERRING_TO]: ShipmentStatus.pickupRequested,
  // pickupNotFound
  [EcDelivereoLabelStatus.NO_DRIVER_FOUND]: ShipmentStatus.pickupRequested, // NOTE: Delivereo uses this status to indicate that the driver was not found, but then their logistic team will try to find a driver, so it's not a permanent status.
  // [EcDelivereoLabelStatus.NO_DRIVER_FOUND]: ShipmentStatus.pickupNotFound, // NOTE: When delivereo uses the NO_DRIVER_FOUND status as permanent, we should map to this ShipmentStatus.
  // pickingUp
  [EcDelivereoLabelStatus.ACCEPTED]: ShipmentStatus.pickingUp,
  [EcDelivereoLabelStatus.GOING_FIRST_POSITION]: ShipmentStatus.pickingUp,
  [EcDelivereoLabelStatus.ARRIVED_FIRST_POSITION]: ShipmentStatus.pickingUp,
  // inTransit
  [EcDelivereoLabelStatus.GOING_OTHER_POSITION]: ShipmentStatus.inTransit,
  [EcDelivereoLabelStatus.ARRIVED_OTHER_POSITION]: ShipmentStatus.inTransit,
  [EcDelivereoLabelStatus.GOING_LAST_POSITION]: ShipmentStatus.inTransit, // TODO p3 this status should be something like "returnToSender" or "goodsCollectionInTransit"
  [EcDelivereoLabelStatus.ARRIVED_LAST_POSITION]: ShipmentStatus.inTransit, // TODO p3 this status should be something like "returnToSender" or "goodsCollectionInTransit"
  // delivered
  [EcDelivereoLabelStatus.FINISHED]: ShipmentStatus.delivered,
  [EcDelivereoLabelStatus.RATING]: ShipmentStatus.delivered,
  // annulled
  [EcDelivereoLabelStatus.CANCELED]: ShipmentStatus.annulled, // This status should be found when the user canceled the pickup and is not entitled for a refund. For special ocasions, if the user wants a refund, they must contact customer service. In rare ocassion an admin can manually cancel a shipment, and the user might be entitled for a refund.
  // unknown
  [EcDelivereoLabelStatus.GROUPING]: ShipmentStatus.unknown, // unknown
};
