export const regexNumber = /^\d+$/;

/**
 * Scale used in the Database to store a maximum of X decimal digits
 */
export const NUMBER_DIMENSION_SCALE = 6;

export function toFixedAndRound(value: number | string, precision: number): number {
  if (typeof value === 'number') {
    value = value;
  } else if (typeof value === 'string' && !isNaN(Number(value))) {
    value = Number(value);
  } else {
    throw new Error('Invalid input type');
  }
  return parseFloat((+(Math.round(+(value + 'e' + precision)) + 'e' + -precision)).toFixed(precision));
}
