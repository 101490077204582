import {NUMBER_DIMENSION_SCALE, toFixedAndRound} from '../../../utils/common';
import {IQuotation} from '../../quotation/data-access/quotation.model';
import {IRate} from './rate.model';

/** If there's any change in this function, reflect the same change into 'getExtendedAdminQuotation' */
export function getExtendedAdminRate(rate: IRate): IRate | null {
  if (!rate) {
    return null;
  }
  const extendedRate = {
    ...rate,
    _carrierQuotedLabel: rate.carrierQuotedLabel,
    _carrierQuotedLabelVat: rate.carrierQuotedLabelVat,
    _carrierQuotedInsurance: rate.carrierQuotedInsurance,
    _carrierQuotedInsuranceVat: rate.carrierQuotedInsuranceVat,
    _carrierQuotedCollection: rate.carrierQuotedCollection,
    _carrierQuotedCollectionVat: rate.carrierQuotedCollectionVat,
    _carrierQuotedSubtotal: rate.carrierQuotedSubtotal,
    _carrierQuotedTotal: rate.carrierQuotedTotal,
    _carrierValidatedLabel: rate.carrierValidatedLabel,
    _carrierValidatedLabelVat: rate.carrierValidatedLabelVat,
    _carrierValidatedInsurance: rate.carrierValidatedInsurance,
    _carrierValidatedInsuranceVat: rate.carrierValidatedInsuranceVat,
    _carrierValidatedCollection: rate.carrierValidatedCollection,
    _carrierValidatedCollectionVat: rate.carrierValidatedCollectionVat,
    _carrierValidatedSubtotal: rate.carrierValidatedSubtotal,
    _carrierValidatedTotal: rate.carrierValidatedTotal,
    _marginPercentCarrierQuotedSubtotal: rate.carrierQuotedSubtotal
      ? toFixedAndRound(1 - rate.carrierQuotedSubtotal / rate.profitSubtotal, NUMBER_DIMENSION_SCALE)
      : null,
    _marginCurrencyCarrierQuotedSubtotal: rate.carrierQuotedSubtotal
      ? rate.profitSubtotal - rate.carrierQuotedSubtotal
      : null,
    _marginPercentCarrierValidatedSubtotal: rate.carrierValidatedSubtotal
      ? toFixedAndRound(1 - rate.carrierValidatedSubtotal / rate.profitSubtotal, NUMBER_DIMENSION_SCALE)
      : null,
    _marginCurrencyCarrierValidatedSubtotal: rate.carrierValidatedSubtotal
      ? rate.profitSubtotal - rate.carrierValidatedSubtotal
      : null,
  } as IRate;

  // delete these values, if for any reason were included in the original rate object
  // @ts-ignore
  delete extendedRate.carrierQuotedLabel;
  // @ts-ignore
  delete extendedRate.carrierQuotedLabelVat;
  delete extendedRate.carrierQuotedInsurance;
  delete extendedRate.carrierQuotedInsuranceVat;
  delete extendedRate.carrierQuotedCollection;
  delete extendedRate.carrierQuotedCollectionVat;
  // @ts-ignore
  delete extendedRate.carrierQuotedSubtotal;
  // @ts-ignore
  delete extendedRate.carrierQuotedTotal;
  delete extendedRate.carrierValidatedLabel;
  delete extendedRate.carrierValidatedLabelVat;
  delete extendedRate.carrierValidatedInsurance;
  delete extendedRate.carrierValidatedInsuranceVat;
  delete extendedRate.carrierValidatedCollection;
  delete extendedRate.carrierValidatedCollectionVat;
  delete extendedRate.carrierValidatedSubtotal;
  delete extendedRate.carrierValidatedTotal;

  return extendedRate;
}

/** If there's any change in this function, reflect the same change into 'getExtendedAdminRate' */
export function getExtendedAdminQuotation(quotation: IQuotation): IQuotation | null {
  if (!quotation) {
    return null;
  }
  const extendedQuotation = {
    ...quotation,
    _carrierQuotedLabel: quotation.carrierQuotedLabel,
    _carrierQuotedLabelVat: quotation.carrierQuotedLabelVat,
    _carrierQuotedInsurance: quotation.carrierQuotedInsurance,
    _carrierQuotedInsuranceVat: quotation.carrierQuotedInsuranceVat,
    _carrierQuotedCollection: quotation.carrierQuotedCollection,
    _carrierQuotedCollectionVat: quotation.carrierQuotedCollectionVat,
    _carrierQuotedSubtotal: quotation.carrierQuotedSubtotal,
    _carrierQuotedTotal: quotation.carrierQuotedTotal,
    _marginPercentCarrierQuotedSubtotal: quotation.carrierQuotedSubtotal
      ? toFixedAndRound(1 - quotation.carrierQuotedSubtotal / quotation.profitSubtotal, NUMBER_DIMENSION_SCALE)
      : null,
    _marginCurrencyCarrierQuotedSubtotal: quotation.carrierQuotedSubtotal
      ? quotation.profitSubtotal - quotation.carrierQuotedSubtotal
      : null,
  } as IQuotation;

  // delete these values, if for any reason were included in the original rate object
  // @ts-ignore
  delete extendedQuotation.carrierQuotedLabel;
  // @ts-ignore
  delete extendedQuotation.carrierQuotedLabelVat;
  delete extendedQuotation.carrierQuotedInsurance;
  delete extendedQuotation.carrierQuotedInsuranceVat;
  delete extendedQuotation.carrierQuotedCollection;
  delete extendedQuotation.carrierQuotedCollectionVat;
  // @ts-ignore
  delete extendedQuotation.carrierQuotedSubtotal;
  // @ts-ignore
  delete extendedQuotation.carrierQuotedTotal;

  return extendedQuotation;
}
