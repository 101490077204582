import {toFixedAndRound} from './number.utils';

export const NUMBER_CURRENCY_SCALE = 2;

export function parseCurrencyNumberToString(value: number): string {
  return toFixedAndRound(value, NUMBER_CURRENCY_SCALE).toString();
}

export function toFixedAndRoundCurrency(value: number | string): number {
  return toFixedAndRound(value, NUMBER_CURRENCY_SCALE);
}
