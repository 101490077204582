import {ApiHttpErrorCodes} from '../../../../api-http-error-codes';
import {NUMBER_DIMENSION_SCALE, toFixedAndRound} from '../../../../utils/common';
import {IDimension} from '../../../../utils/models';
import {
  IEcTramacoLabelReqApi,
  IEcTramacoLabelReqDb,
  IEcTramacoLabelResApi,
  IEcTramacoLabelResDb,
  IEcTramacoQuoteReqApi,
  IEcTramacoQuoteReqDb,
} from './ec-tramaco-label.model';

export function parseEcTramacoAddressPhone(phone: string) {
  let tralingZeros = '';
  const phoneLength = phone.length ?? 0;
  for (let index = phoneLength; index < 9; index++) {
    tralingZeros += '0';
  }
  return tralingZeros + phone;
}

/**
 *
 * @returns // EcTramaco volumetric Weight based on Carrier's fixed formula
 */
export function getEcTramacoVolumetricWeight(dimension: IDimension) {
  const volumetricWeight = (dimension.height * dimension.width * dimension.length) / 8000;
  return toFixedAndRound(volumetricWeight, NUMBER_DIMENSION_SCALE);
}

export function transformEcTramacoQuoteReqApiToDb(ecTramacoQuoteReqApi: IEcTramacoQuoteReqApi): IEcTramacoQuoteReqDb {
  if (!ecTramacoQuoteReqApi?.lstCargaDestino[0]) {
    throw new Error(ApiHttpErrorCodes.ecTramacoTransformQuoteReqApiToDb);
  }

  const ecTramacoQuoteReqDb: IEcTramacoQuoteReqDb = {
    codParroquiaRemit: ecTramacoQuoteReqApi.codParroquiaRemit,
    carga: ecTramacoQuoteReqApi.lstCargaDestino[0].carga,
    codParroquiaDest: ecTramacoQuoteReqApi.lstCargaDestino[0].codParroquiaDest,
    id: ecTramacoQuoteReqApi.lstCargaDestino[0].id,
  };

  return ecTramacoQuoteReqDb;
}

export function transformEcTramacoLabelReqApiToDb(ecTramacoLabelReqApi: IEcTramacoLabelReqApi): IEcTramacoLabelReqDb {
  if (!ecTramacoLabelReqApi?.lstCargaDestino[0]) {
    throw new Error(ApiHttpErrorCodes.ecTramacoTransformLabelReqApiToDb);
  }

  const ecTramacoLabelReqDb: IEcTramacoLabelReqDb = {
    id: ecTramacoLabelReqApi.lstCargaDestino[0].id,
    destinatario: ecTramacoLabelReqApi.lstCargaDestino[0].destinatario,
    carga: ecTramacoLabelReqApi.lstCargaDestino[0].carga,
    remitente: ecTramacoLabelReqApi.remitente,
  };

  return ecTramacoLabelReqDb;
}

export function transformEcTramacoLabelResApiToDb(ecTramacoLabelResApi: IEcTramacoLabelResApi): IEcTramacoLabelResDb {
  if (!ecTramacoLabelResApi.cuerpoRespuesta) {
    throw new Error(ApiHttpErrorCodes.ecTramacoTransformLabelResApiToDb);
  }

  const ecTramacoLabelResDb: IEcTramacoLabelResDb = {
    cuerpoRespuesta: ecTramacoLabelResApi.cuerpoRespuesta,
    id: ecTramacoLabelResApi.salidaGenerarGuiaWs?.lstGuias[0]?.id,
    guia: ecTramacoLabelResApi.salidaGenerarGuiaWs?.lstGuias[0]?.guia,
  };

  return ecTramacoLabelResDb;
}
