import {HourNumbers, MinuteNumbers, WeekdayNumbers} from 'luxon';
import {Relation} from 'typeorm';
import {CollectiveCarrierToken, IBaseUserOwnedEntity, IShipment, IUser} from '../..';
import {Currency} from '../../../utils/models';

export const CarrierPickupAppEnvLocal: Omit<CarrierPickup, 'APP_ENV' | 'collectiveCarrierToken'> = {
  maxManifestTimeHour: 23,
  maxManifestTimeMinute: 59,
  maxLabelGenerationTimeHour: 23,
  maxLabelGenerationTimeMinute: 59,
  sameDayDeliveryTimeHourStart: 0,
  sameDayDeliveryTimeMinuteStart: 0,
  sameDayDeliveryTimeHourEnd: 23,
  sameDayDeliveryTimeMinuteEnd: 59,
  workDays: [1, 2, 3, 4, 5, 6, 7],
};

export interface CarrierPickup {
  APP_ENV: string; // Options: PROD, DEV, LOCAL
  collectiveCarrierToken: CollectiveCarrierToken;
  maxManifestTimeHour: HourNumbers;
  maxManifestTimeMinute: MinuteNumbers;
  maxLabelGenerationTimeHour: HourNumbers;
  maxLabelGenerationTimeMinute: MinuteNumbers;
  sameDayDeliveryTimeHourStart: HourNumbers; // The delivery time range in which we can create a label for a shipment (between sameDayDeliveryTimeHourStart and sameDayDeliveryTimeHourEnd).
  sameDayDeliveryTimeMinuteStart: MinuteNumbers;
  sameDayDeliveryTimeHourEnd: HourNumbers;
  sameDayDeliveryTimeMinuteEnd: MinuteNumbers;
  workDays: WeekdayNumbers[];
}

export interface ManifestPDFTable {
  shipmentId: number;
  carrierLabelUniqueId: number | string;
  recipientAddressName: string;
  recipientAddressCompany?: string;
  recipientAddressCityName: string;
  parcelQuantity: number;
  parcelWeight: number; // physical weight
  parcelVolumetric?: number; // volumetric weight
  parcelContent?: string; // Content of the package
  carrierShipmentProduct?: string; // NOTE: type of Carrier Shipping Service, ex. 'light-weight', 'heavy-weight', 'home-appliance', etc. This is defined by each Carrier.
  goodsValue?: number;
  goodsInsured?: number;
  goodsCollection?: number;
  goodsCurrency?: Currency;
}

export enum ManifestShipmentsToShow {
  first = 'first',
  all = 'all',
}

export interface IManifest extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  /**
   * Date the shipment will be tendered to the carrier.
   * Must be in the format "2014-12-18" (without time).
   * Defaults to current date and time, if no value is provided.
   * Please note that some carriers require this value to be in the future, on a working day, or similar.
   */
  shipmentDate: Date;

  pdfBase64?: string; // PDF in base64 format
  // pdfUrl?: string; // TODO p2, add a link to the pdf URL when file storage service is implemented

  /**
   * OneToMany relation, list of all shipments to be shipped
   */
  shipments: Relation<IShipment[]>;
}
